import * as React from 'react' // eslint-disable-line no-use-before-define

// markup
const NotFoundPage = () => {
  return (
    <div>{/* Do nothing */}</div>
  )
}

export default NotFoundPage
